.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 75px;
  }
  
  .project-card {
    width: calc(50% - 20px);
    max-width: 550px;
    min-width: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease;
    margin: 10px;
  }
  
  .project-card:hover {
    transform: scale(1.05);
  }
  
  .project-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .project-title {
    font-size: 1.5rem;
    margin: 10px;
  }
  
  .project-description {
    text-align: center;
    font-size: 1rem;
    margin: 10px 15px;
    display: block;
  }
  
  .project-link {
    display: inline-block;
    margin: 15px auto;
    padding: 10px 15px;
    background-color: #7356bd;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .project-link:hover {
    text-decoration: underline;
  }