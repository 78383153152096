
.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    flex-wrap: wrap;
    max-width: 80vw;
    padding: 1rem;
    overflow: hidden;
  }
  
  .klk-photo {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 12px;
    border: solid salmon 10px;
    margin-top: 70px;
  }
  
  .about-text-container {
    flex: 1;
    max-width: 60%;
    color:rgb(14, 13, 11);
    font-size: 1.07rem;
    font-weight: bold;
    text-align: justify;
    margin-top: 20px;
    width: 100%;
  }

  @media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      text-align: center;
      gap: 10px;
      width: 100vw;
    }

    .klk-photo {
      max-width: 80%;
      margin: 20px;
      padding: 20px;
    }

    .about-text-container {
      max-width: 90%;
      margin-right: 20px;
      box-sizing: border-box;
      padding: 20px;
    }
  }