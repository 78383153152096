.hero {
    background-image: url('./pix/colorful-2174045_1280.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px; 
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-container {
    display: flex;
    width: 100%;
    width: 100vw;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    position: relative;
  }

  .navbar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .hero-title {
    color: white;
    font-size: 6.5rem;
    font-weight: bold;
    text-shadow: 
      -2px -2px 0 black,  
      2px -2px 0 black,  
      -2px 2px 0 black,  
      2px 2px 0 black; 
    margin-left: 200px;
    margin-bottom: auto;
    margin-top: auto;
    letter-spacing: 4px;
    text-align: center;
    flex: 1;
  }
  
  .job-title {
    color: black;
    font-size: 2.5rem;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
    margin-left: 180px;
  }

  @media (max-width: 768px) {
    .hero {
      height: auto;
      width: 100%;
      background-size: cover;
      margin: 0;
      padding: 0;
      background-position: center;
    }

    .hero-title {
      font-size: 3rem;
      margin-left: 0;
      margin-right: 0;
      padding: 0 10px;
      text-align: center;
    }

    .job-title {
      font-size: 1.5rem;
      margin-left: 0;
      margin-right: 0;
      padding: 0 10px;
      text-align: center;
    }

    .header-container {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }