.App {
  text-align: center;
}


h2 {
  font-size: 42px;
  color: teal;
}

h3 {
  font-size: 26px;
  color: gray
}

body {
  background-color: #333;
  
}



@media (max-width: 768px) {
.App {
  width: 100vw;
  padding: 0;
}

.klk-photo {
  max-width: 80%;
  margin-bottom: 20px;
  margin-right: 25px;
}

body, html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.about-text-container {

  max-width: 80%;
  margin: 10px 25px 10px 12px;
  padding: 10px;
  justify-content: center;
  overflow-wrap: break-word;
}

.tech-icons {
  padding: 20px;
  margin: 0 20px;
  max-width: 70%;
  height: auto;
  box-sizing: border-box;
  size: 2x;
}
}